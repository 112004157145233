<template>
    <div class="test-index-page">
        <div class="editor-wrapper">
            <div id="editable" class="editor" contenteditable="true" v-html="editContent" @input="editContentChange($event)"></div>
            <!-- <div id="editable" class="editor" contenteditable="true" v-html="editContent" @input="editContentChange($event)"></div>-->
            <div id="editable" class="source-editor editor" v-html="editContent" @input="editContentChange($event)"></div> 
        </div>
        
        <div class="margin-t-30">
            <input type="file" @change="fileChange"></input>
        </div>
        <div>
            <video src="https://www.cnuseful.com/testdown/video/test.wmv" width="600" height="400" controls></video>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import tools from '@/utils/tools';
export default {
    components: {

    },
    data(){
        return {
            editContent: `我
<div id="71" tag="true" location="start" draggable="true">
  <span id="71" tag="true" unselectable="on"><span id="71"></span></span>
</div>既有样式
<div id="71" tag="true" location="end" draggable="true">
  <span id="71" tag="true" unselectable="on"><span id="71"></span></span>
</div>
<div id="75" tag="true" location="single" draggable="true">
  <span id="75" tag="true" unselectable="on"><span id="75"></span></span>
</div>
又有图标。`,
            editContentTags: [
                {text: '中国是拥有14亿人口、世界上最大的发展中国家，基础差、底子薄，发展不平衡，', type: 'text'},
                {text:'长期饱受贫困问题困扰', type: 'qa'},
                {text: '。中国的贫困规模之大、', type: 'text'},
                {text: '贫困分布之广', type: 'label'},
                {text: '、贫困程度之深世所罕见，贫困治理难度超乎想象。', type: 'text'},
            ],
        }
    },
    methods: {
        fileChange(event) {
            let fileList = Array.from(event.target.files);
            fileList.map(async file =>{
                console.log(file);
                let ms = await tools.getVideoFileTime(fileList[0]);
                console.log('视频信息：', file.name, tools.msToTime(ms))
            })
            
        },
        editContentChange(e) {
            console.log('xxxx::', e.target)
            this.editContentTag = e.target.innerHTML;
        },
        bindEditerTagEvent() {
            let self = this;
            let sourceEditer = $('.source-editor');
            let editer = $('.editor[contenteditable="true"]');
            let tag = editer.find('div[tag="true"]');
            // 处理按下删除键或后退键时的逻辑
            editer.on('keydown', function(e) {
                if (e.key === 'Backspace' || e.key === 'Delete') {
                    const selection = window.getSelection();
                    if (!selection.isCollapsed) {
                        return;
                    }
                    const currentNode = selection.anchorNode;
                    const offset = selection.anchorOffset;

                    let checkNode = null;
                    let direction = e.key === 'Backspace' ? 'backward' : 'forward';

                    if (direction === 'backward') {
                        if (currentNode.nodeType === Node.TEXT_NODE && offset === 0) {
                            checkNode = currentNode.previousSibling;
                        } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
                            checkNode = currentNode;
                        }
                    } else { // 'forward'
                        if (currentNode.nodeType === Node.TEXT_NODE && offset === currentNode.length) {
                            checkNode = currentNode.nextSibling;
                        } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
                            checkNode = currentNode;
                        }
                    }

                    if (checkNode && checkNode.nodeType === Node.ELEMENT_NODE && checkNode.getAttribute('tag') === 'true') {
                        let range = document.createRange();
                        range.setStartBefore(checkNode);
                        range.setEndAfter(checkNode);

                        let content = range.toString();

                        if (content === '') {
                            e.preventDefault();
                            $(checkNode).remove();
                        }
                    }
                }
            });

            // 处理键盘箭头移动光标时的逻辑
            editer.on('keyup', function(e) {
                const selection = window.getSelection();
                if (!selection.rangeCount) return;
                let range = document.createRange();
                let targetNode = selection.anchorNode;
                if(targetNode.nodeName === 'SPAN'){
                    targetNode = targetNode.parentNode;
                }
                if(['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'].indexOf(e.key) > -1){
                    let moveForward = ['ArrowUp', 'ArrowRight'].indexOf(e.key) > -1;
                    let moveBackward = ['ArrowDown', 'ArrowLeft'].indexOf(e.key) > -1;
                    if (targetNode.nodeType === Node.ELEMENT_NODE && $(targetNode).attr('tag') === 'true') {
                        if (moveForward) {
                            range.setStartAfter(targetNode);
                        } else if (moveBackward) {
                            range.setStartBefore(targetNode);
                        }
                        range.collapse(false);
                        selection.removeAllRanges();
                        selection.addRange(range);
                        e.preventDefault();
                    }
                }
                self.tagPositionChecking(editer);
            });

            // 处理点击标签时移动光标的逻辑
            editer.on('click', function(event) {
                var selection = window.getSelection();
                var range = document.createRange();
                var targetNode = event.target;
                if(targetNode.nodeName === 'SPAN'){
                    targetNode = targetNode.parentNode;
                }
                if ($(targetNode).attr('tag') === 'true') {
                    if ($(targetNode).attr('location') === 'start'){
                        range.setStartAfter(targetNode);
                    }else if($(targetNode).attr('location') === 'end'){
                        range.setStartBefore(targetNode);
                    }
                    range.collapse(false);
                    selection.removeAllRanges();
                    selection.addRange(range);
                    event.preventDefault();
                }
            });

            // 处理拖拽逻辑
            var isDragging = false;
            var draggedElement = null;
            var lastKnownPosition = null;

            tag.on('mousedown', function(e) {
                isDragging = true;
                draggedElement = this;
                e.preventDefault();
            });

            editer.on('mousemove', function(e) {
                if (isDragging && draggedElement) {
                    const newRange = document.caretRangeFromPoint(e.clientX, e.clientY);
                    if (newRange) {
                        lastKnownPosition = newRange;
                        const selection = window.getSelection();
                        selection.removeAllRanges();
                        selection.addRange(newRange);
                    }
                }
            });

            //$(document)
            editer.on('mouseup', function(e) {
                if (isDragging) {
                    isDragging = false;

                    if (lastKnownPosition && !isWithinTagTrue($(e.target))) {
                        const selection = window.getSelection();
                        selection.removeAllRanges();
                        selection.addRange(lastKnownPosition);

                        const range = selection.getRangeAt(0);
                        range.insertNode(draggedElement);
                        range.collapse(false);

                        selection.removeAllRanges();
                        selection.addRange(range);
                    }
                    self.tagPositionChecking(editer);
                    draggedElement = null;
                }
            });

            // 功能函数来检查元素是否位于具有tag="true"属性的<div>内或是其子元素
            function isWithinTagTrue($element) {
                while ($element.length && $element[0] !== document) {
                    if ($element.is('div[tag="true"]')) {
                        return true;
                    }
                    $element = $element.parent();
                }
                return false;
            }

            sourceEditer.find('div[tag="true"]').on('click', function(e) {
                var clonedTag = $(this).clone();
                var sel = window.getSelection();
                if (!sel.rangeCount) return; 

                var range = sel.getRangeAt(0);
                range.deleteContents(); 

                var frag = document.createDocumentFragment();
                var newElement = clonedTag.get(0);
                $(newElement).on('mousedown', function(e) {
                    isDragging = true;
                    draggedElement = this;
                    e.preventDefault();
                });
                frag.appendChild(newElement);

                range.insertNode(frag);
                range.setStartAfter(newElement);
                range.collapse(true);

                sel.removeAllRanges();
                sel.addRange(range);

                self.tagPositionChecking(editer);
                e.preventDefault();
            });
        },
        unbindEditerTagEvent() {
            let editer = $('.editor[contenteditable="true"]');
            editer.off('keydown');
            editer.off('keyup');
            editer.off('click');
            editer.off('mousemove');
            editer.off('mouseup');
            let tag = $('div[tag="true"]');
            tag.off('mousedown');
        },
        tagPositionChecking(editerNode) {
            editerNode.find('span[zeroWidthSpace="true"]').remove();
            var tags = editerNode.find('div[tag="true"]');
            // 创建零宽空格span元素
            var zeroWidthSpaceSpan = '<span zeroWidthSpace="true">&ZeroWidthSpace;</span>';
            tags.each(function(index) {
                var $this = $(this);
                var prevNode = this.previousSibling;
                var nextNode = this.nextSibling;

                var prevTextContent = "";
                while(prevNode) {
                    if(prevNode.nodeType === Node.TEXT_NODE && prevNode.textContent.length > 0) {
                        prevTextContent = prevNode.textContent;
                        break;
                    }
                    prevNode = prevNode.previousSibling;
                }

                var nextTextContent = "";
                while(nextNode) {
                    if(nextNode.nodeType === Node.TEXT_NODE && nextNode.textContent.length > 0) {
                        nextTextContent = nextNode.textContent;
                        break;
                    }
                    nextNode = nextNode.nextSibling;
                }

                if(prevTextContent.length === 0) {
                    $this.before(zeroWidthSpaceSpan);
                    // console.log('标签位于句子的开始:', this);
                }
                if(nextTextContent.length === 0) {
                    $this.after(zeroWidthSpaceSpan);
                    // console.log('标签位于句子的结束:', this);
                }

                if (index < tags.length - 1) {
                    var nextTag = tags[index + 1];
                    var hasTextBetween = false;

                    var node = this.nextSibling;
                    while (node && node !== nextTag) {
                        if ((node.nodeType === Node.TEXT_NODE || (node.nodeType === Node.ELEMENT_NODE && !node.hasAttribute('tag'))) && node.textContent.length > 0) {
                            hasTextBetween = true;
                            break;
                        }
                        node = node.nextSibling;
                    }

                    if (!hasTextBetween) {
                        $this.after(zeroWidthSpaceSpan);
                        // console.log('两个标签之间没有文本:', this, nextTag);
                    }
                }
            });
        },
    },
    mounted() {
        this.bindEditerTagEvent();
    }
}
</script>
<style lang="scss" scoped>

.test-index-page {
    margin-top: 20px;
    .editor-wrapper {
        position: relative;
        
        border-radius: 10px;
        .editor {
            // -webkit-user-modify: read-write-plaintext-only;
            background-color: #fff;
            position: relative;
            outline: none;
            line-height: 1.7;
            padding: 15px;
            z-index: 1;
            margin-bottom: 10px;
            ::v-deep div[tag="true"]{
                $tagHeight: 14px;
                $tagBorderWidth: $tagHeight / 2;
                $tagPosition: ($tagHeight / 2) - 0.5px;
                position: relative;
                display: inline-block;
                user-select: none;
                margin: 0 5px;
                cursor: pointer;
                span[tag="true"] {
                    position: relative;
                    display: inline-block;
                    background-color: #7DD6B3;
                    color: #fff;
                    height: $tagHeight;
                    line-height: $tagHeight;
                    user-select: none;
                    &::before {
                        content: " ";
                        position: absolute;
                        left: -$tagPosition;
                        width: 0;
                        height: 0;
                        border-top: $tagBorderWidth solid rgba(0,0,0,0);
                        border-bottom: $tagBorderWidth solid rgba(0,0,0,0);
                        box-sizing: border-box;
                        visibility: hidden;
                    }
                    &::after {
                        content: " "; //attr(id)
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-top: $tagBorderWidth solid rgba(0,0,0,0);
                        border-bottom: $tagBorderWidth solid rgba(0,0,0,0);
                        box-sizing: border-box;
                        visibility: hidden;
                    }
                    > span {
                        &::after {
                            content: attr(id);
                            position: relative;
                            font-size: 12px;
                            padding: 0 6px;
                        }
                    }
                }
                
                &[location="start"] {
                    padding-right: $tagPosition;
                    padding-left: 0;
                    span[tag="true"] {
                        &::after {
                            border-left: $tagBorderWidth solid #7DD6B3;
                            visibility: visible;
                        }
                    }
                }
                &[location="end"] {
                    padding-left: $tagPosition;
                    padding-right: 0;
                    span[tag="true"] {
                        &::before {
                            border-right: $tagBorderWidth solid #7DD6B3;
                            visibility: visible;
                        }
                    }
                }
                &[location="single"] {
                    padding-left: $tagPosition;
                    padding-right: $tagPosition;
                    span[tag="true"] {
                        &::after {
                            border-left: $tagBorderWidth solid #7DD6B3;
                            visibility: visible;
                        }
                        &::before {
                            border-right: $tagBorderWidth solid #7DD6B3;
                            visibility: visible;
                        } 
                    }
                }
            }


            &.tag {
                color: transparent;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                z-index: 1;
                .qa {
                    position: relative;
                    z-index: 2;
                    background-color: rgb(252, 228, 228);
                    border-bottom: 2px solid transparent;
                    &:hover {
                        border-color: #941919;
                    }
                }
                .label {
                    position: relative;
                    z-index: 2;
                    background-color: #dbd6f5;
                    border-bottom: 2px dashed transparent;
                    &:hover {
                        border-color: #13025f;
                    }
                }
            }
            
        }
    }

    
}
</style>

